/*global createjs, AdobeAn*/

import React from 'react';
import TweenMax from "gsap/umd/TweenMax";
// This component requires createjs and animate cc to be included into scripts

export class AnimationView extends React.Component {

    componentDidMount() {
        let that = this;
        this.canvas = this.refs.animationCanvas; // document.getElementById("canvas");
        this.anim_container = this.refs.animationContainer; // document.getElementById("animation_container");
        this.responsive_container = this.refs.responsiveContainer;
        this.dom_overlay_container = this.refs.overlayContainer; // document.getElementById("dom_overlay_container");
        this.comp = window.AdobeAn.getComposition("213B96E7C6374CFCB2948CE4C9204853");
        this.lib = this.comp.getLibrary();
        this.loader = new createjs.LoadQueue(false);
        this.loader.addEventListener("fileload", function(evt){that.handleFileLoad(evt,that.comp)});
        this.loader.addEventListener("complete", function(evt){that.handleComplete(evt,that.comp)});
        this.loader.loadManifest(this.lib.properties.manifest);

        this.props.backgroundMusic.stop();
        this.props.backgroundMusic.volume(this.props.musicVolume);
        this.props.backgroundMusic.play();

        window.animationFinished = this.handleAnimationFinished;

        TweenMax.to(this.viewContainer, 2, {delay: 1, opacity: 1});
    }

    componentWillUnmount() {
        this.props.backgroundMusic.fade(this.props.musicVolume, 0, 1000);
    }

    handleFileLoad(evt, comp) {
        let images=comp.getImages();
        if (evt && (evt.item.type === "image")) { images[evt.item.id] = evt.result; }
    }

    handleComplete(evt, comp) {
        const lib=this.comp.getLibrary();
        let ss=this.comp.getSpriteSheet();
        let queue = evt.target;
        let ssMetadata = lib.ssMetadata;
        for(let i=0; i<ssMetadata.length; i++) {
            ss[ssMetadata[i].name] = new createjs.SpriteSheet( {"images": [queue.getResult(ssMetadata[i].name)], "frames": ssMetadata[i].frames} )
        }
        const exportRoot = new this.lib.BWconfessanimationv5();
        const stage = new this.lib.Stage(this.canvas);
        stage.enableMouseOver();

        const fnStartAnimation = function() {
            stage.addChild(exportRoot);
            createjs.Ticker.setFPS(lib.properties.fps);
            createjs.Ticker.addEventListener("tick", stage);
        }

        this.makeResponsive(true,'both',true,1, stage);
        AdobeAn.compositionLoaded(lib.properties.id);
        fnStartAnimation();
    }

    handleAnimationFinished = () => {
        this.props.backgroundMusic.fade(this.props.musicVolume,0,1);
        TweenMax.to(this.viewContainer, 1, {opacity: 0, onComplete:() => {
            this.props.backgroundMusic.stop();
            this.props.history.push('/console/');
        }});
    }

    makeResponsive(isResp, respDim, isScale, scaleType, stage) {
        let lastW, lastH, lastS=1;
        let that = this;
        window.addEventListener('resize', resizeCanvas);
        resizeCanvas();

        function resizeCanvas() {
            let w = that.lib.properties.width, h = that.lib.properties.height;
            let iw = window.innerWidth, ih=window.innerHeight;
            let pRatio = window.devicePixelRatio || 1, xRatio=iw/w, yRatio=ih/h, sRatio=1;
            if(isResp) {
                if((respDim==='width'&&lastW===iw) || (respDim==='height'&&lastH===ih)) {
                    sRatio = lastS;
                }
                else if(!isScale) {
                    if(iw<w || ih<h)
                        sRatio = Math.min(xRatio, yRatio);
                }
                else if(scaleType===1) {
                    sRatio = Math.min(xRatio, yRatio);
                }
                else if(scaleType===2) {
                    sRatio = Math.max(xRatio, yRatio);
                }
            }
            that.canvas.width = w*pRatio*sRatio;
            that.canvas.height = h*pRatio*sRatio;
            that.canvas.style.width = that.dom_overlay_container.style.width = that.anim_container.style.width =  w*sRatio+'px';
            that.canvas.style.height = that.anim_container.style.height = that.dom_overlay_container.style.height = h*sRatio+'px';
            stage.scaleX = pRatio*sRatio;
            stage.scaleY = pRatio*sRatio;
            lastW = iw; lastH = ih; lastS = sRatio;
            stage.tickOnUpdate = false;
            stage.update();
            stage.tickOnUpdate = true;
        }
    }



    render() {
        return (
            <div className="view-container" style={{opacity: 0}} ref={(el) => { this.viewContainer = el }}>
                <div id="responsive_container" ref="responsiveContainer">
                    <div id="animation_container" ref="animationContainer" className="animation-container">
                        <canvas id="canvas" ref="animationCanvas" width="1920" height="1080" className="animation-canvas" />
                        <div id="dom_overlay_container" ref="overlayContainer" className="dom-overlay-container" />
                    </div>
                </div>
            </div>
        );
    }
}
