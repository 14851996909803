import React from 'react';
import TweenMax from "gsap/umd/TweenMax";

export class LoadingAnimation extends React.Component {

	componentDidMount() {
        TweenMax.to(this.element, 0.5, {opacity: 1});
	}	

    render() {
        return (
	        <div style={{ opacity:0 }} className={"main-loader vh-100 vw-100 align-content-center justify-content-center bg-dark"} ref={(el) => { this.element = el }}>
	            <div className="p-4 align-self-center">
	                <h1 className="h3 p-0 m-0 loading-text position-relative">Loading assets</h1>
	            </div>
	        </div>
        );
    }
}