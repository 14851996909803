const DEBUG_MODE = process.env.NODE_ENV === 'production' ? false : true;

var devSettings = {
    baseUrl: 'http://localhost:8080',
    shareUrl: 'http://localhost:8080'
}

var prodSettings = {
    baseUrl: 'https://tunnustasyntisi.fi/',
    shareUrl: 'https://tunnustasyntisi.fi/'
}

export const Settings = DEBUG_MODE ? devSettings : prodSettings;
