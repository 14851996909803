import React from 'react';
import { Settings } from '../settings';
import TweenMax from 'gsap/umd/TweenMax';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, FacebookIcon, TwitterIcon, LinkedinIcon } from 'react-share';
import bitwise_pikkelssi from '../assets/bitwise_pikkelssi.png';

export class EndingView extends React.Component {

    constructor(props) {
        super(props);
        this.endingContent = React.createRef();
    }

    componentDidMount() {
        TweenMax.to(this.endingContent.current, 3, {opacity: 1});
        this.props.backgroundMusic.stop();
        this.props.backgroundMusic.volume(this.props.musicVolume);
        this.props.backgroundMusic.play();
    }

    componentWillUnmount() {
        this.props.backgroundMusic.fade(this.props.musicVolume, 0, 1000);
    }

    render() {

        const confession = "Olen ohjelmistokehittäjä ja olen tehnyt syntiä" + (this.props.location.state && this.props.location.state.confession ? ": " + this.props.location.state.confession : ".");

        return (
            <div className="view-container">
                <div ref={ this.endingContent } className={"ending-content o-0 d-flex flex-column"}>
                    <div className={"ending-content-inner h-100 text-center flex-grow-1 d-flex align-items-center justify-content-center flex-column"}>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <h1 className="h3">Tuntuu kevyeltä, eikö?</h1>
                                    <p>Näytä tietä muille syntisille ja ohjaa heidät konsolin valoon.</p>
                                    <ul className={"nav justify-content-center"}>
                                        <li className="nav-item mr-3 ml-3">
                                            <FacebookShareButton quote={confession} url={Settings.shareUrl}><FacebookIcon size={48}/></FacebookShareButton>
                                        </li>
                                        <li className="nav-item mr-3">
                                            <LinkedinShareButton description={confession} url={Settings.shareUrl}><LinkedinIcon size={48}/></LinkedinShareButton>
                                        </li>
                                        <li className="nav-item mr-3">
                                            <TwitterShareButton title={confession} url={Settings.shareUrl}><TwitterIcon size={48}/></TwitterShareButton>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="bitwise-logo mb-5 fixed-bottom">
                            <div className="container">
                                <div className={"row justify-content-center"}>
                                    <div className={"col-6 col-sm-4 col-md-3"}>
                                        <a href="https://bitwise.fi" target="_blank" rel="noopener noreferrer">
                                            <img src={bitwise_pikkelssi} alt="Bitwise logo" className="img-fluid" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
