import React from 'react';
import { Route } from 'react-router-dom';
import { Welcome } from './console/Welcome';
import { Confess } from './console/Confess';
import { ListStream } from './console/ListStream';
// import { Sweepstakes } from './console/Sweepstakes';

let innerHeight = require('ios-inner-height');

export class ConsoleView extends React.Component {

    state = {
        buffer: [],
        quotes: [],
        inputValue: '',
        waiting: false,
        userConfession: '',
        userEmail: '',
        windowHeight: 0,
        windowWidth: 0
    };

    updateWindowDimensions = () => {
        this.setState({ windowHeight: innerHeight, windowWidth: window.innerWidth });
    };

    componentDidMount() {
        this.props.backgroundMusic.play();
        this.props.backgroundMusic.fade(0,this.props.musicVolume,1000);
        window.addEventListener('resize', this.updateWindowDimensions);
        this.updateWindowDimensions();
    }

    componentWillUnmount() {
        this.props.backgroundMusic.fade(this.props.musicVolume,0,1000);
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    render() {

        const { path } = this.props.match;

        return (

            <div className="console-view-container" style={{height: this.windowHeight}}>
                <div className="console-wrapper">
                    <div className={'console' + (this.state.waiting ? ' waiting' : '') }>
                        <Route path={path} exact render={(props) => <Welcome {...props}  />} />
                        <Route path={`${path}confess`} render={(props) => <Confess {...props} books={ this.props.books }  />} />
                        <Route path={`${path}list`} render={(props) => <ListStream {...props} quotes={ this.props.quotes } />} />
                        {/* <Route path={`${path}win`} render={(props) => <Sweepstakes {...props}  />} /> */}
                    </div>

                </div>
                <div className="console-overlay"></div>
            </div>

        );
    }
}
