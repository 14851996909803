import * as axios from "axios";
import React from 'react';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import uuidv4 from 'uuid/v4';
import arrow from '../../assets/arrow.png';
import FbIcon from '../../assets/fb.png';
import LiIcon from '../../assets/li.png';
import TwIcon from '../../assets/tw.png';
import { Settings } from "../../settings";


export class ListStream extends React.Component {

    state = {
        page: 0,
        itemsPerPage: 8,
        sorting: 'date',
        quotes: [],
        quotesByVotes: [],
        waiting: false
    };

    clientIdKey = "clientId";

    static getDerivedStateFromProps(props, state) {
        if (props.quotes && props.quotes.length) {
            return {
                quotes: props.quotes.concat().sort((a,b) => {
                    const dateA = a.date ? new Date(a.date).getTime() : 0;
                    const dateB = b.date ? new Date(b.date).getTime() : 0;
                    if (dateA < dateB) {
                        return 1;
                    } else if (dateA > dateB) {
                        return -1;
                    } else {
                        return 0;
                    }
                }),
                quotesByVotes: props.quotes.concat().sort((a,b) => {
                    if (a.upvotes < b.upvotes) {
                        return 1;
                    } else if (a.upvotes > b.upvotes) {
                        return -1;
                    } else {
                        return 0;
                    }
                }).slice(0,30)
            }
        }
    }

    componentDidMount() {
        this.setClientId();
        window.addEventListener("keyup", (e) => {
            this.onKeyUp(e);
        });
    }

    setClientId = () => {
        if (!localStorage.getItem(this.clientIdKey)) {
            window.localStorage.setItem(this.clientIdKey, uuidv4());
        }
    }

    prevPage = () => {
        this.setState({page: this.state.page - 1});
    }

    nextPage = () => {
        this.setState({page: this.state.page + 1});
        document.getElementById('confessionListScrollable').scrollIntoView();
    }

    upVote = (quote) => {
        this.setClientId();
        if (!quote.voted) {
            setTimeout(() => {
                // add a vote for the confession
                this.voteRequest = axios
                    .post(Settings.baseUrl + '/api/confession/' + quote.id + '/upvote', {
                        clientId: window.localStorage.getItem(this.clientIdKey)
                    })
                    .then((result) => {
                        quote.upvotes++;
                        quote.voted = true;
                        this.forceUpdate();
                    })
                    .catch((error) => {
                        console.log(error);
                        this.goBack();
                    });
            });
        }
    }

    goBack = () => {
        this.props.history.push('/console/');
    }

    sortByDate = () => {
        this.setState({sorting: 'date', page: 0});
    }

    sortByVotes = () => {
        this.setState({sorting: 'votes', page: 0});
    }

    wait(cb, duration) {
        this.setState({waiting: true});
        setTimeout(() => {
            this.setState({waiting: false});
            if (typeof cb === 'function') cb();
        }, duration || 300);
    }

    getQuotesLength = () => {
        if (this.state.sorting === 'votes') {
            return this.state.quotesByVotes.length;
        } else {
            return this.state.quotes.length;
        }
    }

    onKeyUp = (e) => {
        switch (e.keyCode) {
            case 37:
                if (this.state.page > 0) {
                    this.prevPage();
                }
                break;
            case 39:
            case 32:
                if ((this.state.page + 1) * this.state.itemsPerPage < this.getQuotesLength()) {
                    this.nextPage();
                }
                break;
            case 38:
            case 27:
            case 88:
            case 81:
                this.goBack();
                break;
            default:
                break;
        }
    }

    render() {

        const first = this.state.itemsPerPage * this.state.page;
        const last = first + this.state.itemsPerPage;

        return (
            <div className="text-left" id="confessionListScrollable" onKeyDown={this.onKeyUp}>
                <div className="row">
                    <div className="col-12 col-md-6 align-self-center">
                        <h1 className={"h5 text-center text-md-left"}>SYNTIEN KANSIO</h1>
                    </div>
                    <div className="col-12 col-md-6 align-self-center">
                        <ul className={"nav justify-content-center justify-content-md-end sorting-nav"}>
                            <li className={'nav-item ' + (this.state.sorting === 'date' ? 'active' : '')}>
                                <button className="btn btn-sm btn-primary" onClick={this.sortByDate}>Uusimmat</button>
                            </li>
                            <li className={'nav-item ' + (this.state.sorting === 'votes' ? 'active' : '')}>
                                <button className="btn btn-sm btn-primary" onClick={this.sortByVotes}>Suosituimmat</button>
                            </li>
                        </ul>
                    </div>
                </div>

                <p>&nbsp;</p>

                {
                    (this.state.sorting === 'votes' ? this.state.quotesByVotes : this.state.quotes)
                        .slice(first, last)
                        .map((quote, index) => {
                            return (
                                <div key={quote.id} className={"mb-4 mb-md-2 d-flex flex-wrap align-items-start"}>

                                    <button className={"btn btn-primary btn-sm mr-2 col-auto text-md-center"} title={"Äänestä tätä tunnustusta"} onClick={(evt) => this.upVote(quote)}>
                                        <img src={arrow} alt="Arrow symbol" className={"mr-1"} width="16px" height="16px" /> {quote.upvotes}
                                    </button>

                                    <div className={"col col-auto list-some-buttons " + (quote.voted ? " active" : "")}>
                                        <span className="col col-auto btn btn-primary btn-sm" title={"Jaa Facebookissa"}>
                                            <FacebookShareButton url={Settings.shareUrl} quote={quote.text}><img
                                                src={FbIcon} alt='Facebook' style={{width: '16px', height: '16px'}}/></FacebookShareButton>
                                        </span>
                                        < span className="col col-auto col-md-auto btn btn-primary btn-sm" title={"Jaa Linkedinissä"} >
                                            <LinkedinShareButton url={Settings.shareUrl} description={quote.text}><img src={LiIcon} alt='LinkedIn' style={{width: '16px', height: '16px'}} /></LinkedinShareButton>
                                            </span>
                                            <span className="col col-auto col-md-auto btn btn-primary btn-sm" title={"Jaa Twitterissä"} >
                                            <TwitterShareButton title={quote.text} url={Settings.shareUrl} ><img src={TwIcon} alt='Twitter' style={{
                                            width: '16px',
                                            height: '16px'
                                        }} /></TwitterShareButton>
                                            </span>
                                    </div>

                                    <div className="text col-md d-block d-md-float text-wrap"><p>{ quote.text.replace(/\u00A0/g, ' ') }</p></div>

                                </div>);
                        })
                }

                <p>&nbsp;</p>

                <div className="row align-content-around mt-5 align-items-center">
                    <div className="col text-right">{ this.state.page > 0 && <button className="btn btn-primary arrow" onClick={this.prevPage}>&lt;--</button> }</div>
                    <div className="col col-auto text-center">Sivu {this.state.page + 1} / { Math.ceil(this.getQuotesLength() / this.state.itemsPerPage) } </div>
                    <div className="col text-left">{ (this.state.page + 1) * this.state.itemsPerPage < this.getQuotesLength() && <button className="btn btn-primary arrow" onClick={this.nextPage}>--&gt;</button> }</div>
                </div>
                <div className="row">
                    <div className="col text-center">
                        <button className="btn btn-primary" onClick={this.goBack}>Sulje (esc tai x)</button>
                    </div>
                </div>

            </div>
        );
    }
}