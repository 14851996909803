import React from 'react';

export class Welcome extends React.Component {

    state = {
        inputValue: '',
        waiting: false,
        buffer: []
    };

    welcomeMessage = `
        <div class="mt-4">
            <p>TERVETULOA TUNNUSTUS&shy;PÄÄTTEELLE. SYÖTÄ KOMENTO:</p>
            <ul>
                <li>TUNNUSTA - tee tunnustus</li>
                <li>SYNNIT - lue syntien arkistoa</li>
                <li>HELP - Listaa käytettävissä olevat käskyt</li>
                <li>EXIT - poistu konsolista</li>
            </ul>
        </div>
    `;

    prompt = 'bw$';

    componentDidMount() {
        document.body.addEventListener('click', this.setFocus);
        this.setFocus();
        this.setCaretListener();
        this.consoleWrite(this.welcomeMessage);
    }

    componentWillUnmount() {
        document.body.removeEventListener('click', this.setFocus);
    }

    onConsoleChange = (e) => {
        this.setEndOfContenteditable(e.target);
        this.setState({
            inputValue: e.target.textContent,
        });
    }

    onConsoleKeyUp = (e) => {
        if (e.key === 'Enter') {
            this.evalInput(e.target.textContent);
            e.target.innerHTML = "";
        }
    }

    quit() {
        this.props.history.push('/end/');
    }

    consoleWrite = (input) => {
        this.setState(prevState => ({
            buffer: [...prevState.buffer, input]
        }), () => {
            // Move to bottom of the div
            document.getElementById("consoleBufferList").scrollIntoView(false);
        });
    }

    stripHtml(html)
    {
        let tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    }

    evalInput = (rawInput) => {

        // strip html
        const input = this.stripHtml(rawInput);

        // write command to buffer
        this.consoleWrite(this.prompt + ' ' + input);

        // clear input
        this.setState({
            inputValue: ''
        });

        if (!input.length) return;

        // eval command
        const command = input.split(' ')[0].toLowerCase();
        switch(command) {

            case 'man':
            case 'ls':
            case 'help':
                this.consoleWrite(this.welcomeMessage);
                break;
            case 'clear':
                this.setState({buffer: []});
                break;
            case 'synnit':
            case 'list':
            case 'arkisto':
                this.props.history.push('/console/list/');
                break;
            // case 'arvonta':
            //     this.props.history.push('/console/win/');
            //     break;
            case 'quit':
            case 'exit':
                this.quit();
                break;
            case 'tunnusta':
                this.props.history.push('/console/confess/');
                break;
            default:
                this.consoleWrite('SYNTAX ERROR: komentoa ' + command + ' ei löytynyt.');
                break;
        }
    }

    setFocus = () => {
        this.consoleInput.focus({ preventScroll: true });
        this.setEndOfContenteditable(this.consoleInput);
    }

    setEndOfContenteditable(contentEditableElement) {
        var range, selection;
        if (document.createRange) {
            range = document.createRange();
            range.selectNodeContents(contentEditableElement);
            range.collapse(false);
            selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
        } else if(document.selection) {
            range = document.body.createTextRange();
            range.moveToElementText(contentEditableElement);
            range.collapse(false);
            range.select();
        }
    }

    setCaretListener = () => {
        this.consoleInput.addEventListener('paste', e => {
            e.preventDefault();
        });
        this.consoleInput.addEventListener('keydown', e => {
            this.setEndOfContenteditable(e.target);
            if (e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Enter') {
                e.preventDefault();
            }
        });
    }

    render() {
        return (
            <div onClick={this.setFocus} id="consoleBufferList">

                <div>
                    { this.state.buffer.map((row, index) => {
                        return (
                            <div key={index} className={"buffer-row text-break"} dangerouslySetInnerHTML={{ __html: row }} />
                        );
                    })}
                </div>

                <div className={this.state.waiting ? 'd-none' : ' console-input-display'} ref={(el) => { this.inputDisplay = el }}>
                    { this.prompt } <span type="text" disabled={this.state.waiting}
                       onKeyDown={e => this.setEndOfContenteditable(e.target)}
                       onInput={e => this.onConsoleChange(e)}
                       onKeyUp={e => this.onConsoleKeyUp(e)}
                       className="console-hidden-input text-select text-break"
                       contentEditable="true"
                       ref={(input) => { this.consoleInput = input }}></span>
                </div>


            </div>);
    }
}