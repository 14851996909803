import React from 'react';
import { render } from 'react-dom';
import { Settings } from './settings';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { AnimationView } from './components/AnimationView';
import { ConsoleView } from './components/ConsoleView';
import { WelcomeView } from "./components/WelcomeView";
import { TermsView } from "./components/TermsView";
import { EndingView } from "./components/EndingView";
import { LoadingAnimation } from "./components/LoadingAnimation";
import { Howl } from 'howler';
import * as axios from "axios";
import './style.css';

class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            quotes: [],
            books: [],
            dataLoaded: false,
            soundsLoaded: false,
            spritesLoaded: false,
            termsAgreed: window.localStorage.getItem("confessionCampaignTermAgreed") === 'true'
        };

        // allow global access for the "flash"-animation
        window.Howl = Howl;

        this.animationSpritesUrl = '/assets/images/BW_confess_animation_v5_atlas_.png';
        this.onSpritesLoaded = this.onSpritesLoaded.bind(this);
        this.onSoundLoaded = this.onSoundLoaded.bind(this);
        this.agreeTerms = this.agreeTerms.bind(this);

    }

    componentDidMount() {

        let that = this;

        this.introMusic = new Howl({
            src: ['/assets/sounds/intro.mp3'],
            autoplay: false,
            loop: true,
            onload: this.onSoundLoaded
        });

        this.churchMusic = new Howl({
            src: ['/assets/sounds/church2.mp3'],
            autoplay: false,
            loop: true,
            onload: this.onSoundLoaded
        });

        this.outroMusic = new Howl({
            src: ['/assets/sounds/outro.mp3'],
            autoplay: false,
            loop: false,
            onload: this.onSoundLoaded
        });

        this.consoleMusic = new Howl({
            src: ['/assets/sounds/humm.mp3'],
            autoplay: false,
            loop: true,
            onload: this.onSoundLoaded
        });

        this.sounds = [
            this.introMusic,
            this.consoleMusic,
            this.outroMusic,
            this.consoleMusic
        ];

        this.confessionsRequest = axios
            .get(Settings.baseUrl + '/api/confession')
            .then((result) => {
                that.setState({
                    quotes: result.data,
                    confessionsLoaded: true
                });
            })
            .catch((error) => {
                console.log(error);
            });

        this.request = axios
            .get('/assets/data/data.json')
            .then((result) => {
                that.setState({
                    books: result.data.books,
                    soundsOnText: result.data.soundsOnText,
                    booksLoaded: true
                });
            })
            .catch((error) => {
                console.log(error);
            });

        // Welcome message
        console.log("Meillä tehdään maailman parasta koodia. Tämä sivusto on mainostoimiston käsialaa, ensi kerralla teemme itse. ;)");
    }

    componentWillUnmount() {
        this.request.abort();
    }

    onSpritesLoaded() {
        this.setState({spritesLoaded: true});
    }

    onSoundLoaded() {
        let soundsLoaded = 0;
        this.sounds.forEach((sound) => {
            if (sound.state() === "loaded") {
                soundsLoaded++;
            }
        });
        if (soundsLoaded === this.sounds.length) {
            this.setState({soundsLoaded: true});
        }
    }

    agreeTerms() {
        window.localStorage.setItem('confessionCampaignTermAgreed', 'true');
        this.setState({
            termsAgreed: true
        });
    }

    render() {

            const app = (
                <Router>
                    <div className="h-100">
                        {   this.state.soundsLoaded &&
                            this.state.spritesLoaded &&
                            this.state.confessionsLoaded &&
                            this.state.booksLoaded ? (

                            <div className="h-100">
                                <Switch>
                                    <Route
                                        exact
                                        path="/"
                                        render={(props) => <WelcomeView
                                            {...props}
                                            quotes={this.state.quotes }
                                            soundsOnText={this.state.soundsOnText }
                                            musicVolume={0.3}
                                            backgroundMusic={ this.introMusic }
                                        />}
                                    />
                                    <Route
                                        path="/animation"
                                        render={(props) => <AnimationView
                                            {...props}
                                            musicVolume={0.3}
                                            backgroundMusic={ this.churchMusic }
                                        />}
                                    />
                                    <Route
                                        path="/console/"
                                        render={(props) => <ConsoleView
                                            {...props}
                                            musicVolume={0.1}
                                            quotes={ this.state.quotes }
                                            books={ this.state.books }
                                            backgroundMusic={ this.consoleMusic }
                                        />}
                                    />
                                    <Route
                                        path="/end"
                                        render={(props) => <EndingView
                                            {...props}
                                            musicVolume={0.3}
                                            backgroundMusic={ this.outroMusic }
                                        />}
                                    />
                                    <Redirect to='/' />
                                </Switch>
                            </div>
                        ) : (

                            <LoadingAnimation />
                        )}
                        <img src={ this.animationSpritesUrl } alt="Preload Element" className={"d-none"} onLoad={this.onSpritesLoaded} />
                    </div>
                </Router>
            );

            const terms = (
                <TermsView onAgreeClick={this.agreeTerms}/>
            );

            return (
                <main className="h-100">
                    { app }
                    { this.state.termsAgreed ? '' : terms }
                </main>
            );

    }


}

render(<App/>, document.getElementById('app'));