import React from 'react';
import { Settings } from '../../settings';
import _ from 'lodash';
import * as axios from "axios";

export class Confess extends React.Component {

    state = {
        waiting: false,
        submitting: false,
        submitted: false,
        confession: '',
        randomBooks: [],
        inputValue: '',
        consoleState: 'confess'
    };

    componentDidMount() {
        document.body.addEventListener('click', this.setFocus);
        this.setFocus();
        this.setListeners();
    }

    componentWillUnmount() {
        document.body.removeEventListener('click', this.setFocus);
    }

    onConsoleChange = (e) => {
        this.setEndOfContenteditable(e.target);
        if (this.state.consoleState === 'confess') {
            this.setState({
                inputValue: e.target.textContent,
            });
        }
    };

    onConsoleKeyUp = (e) => {
        if (e.key === 'Enter') {
            this.evalInput(e.target.textContent);
        }

        if (e.keyCode === 27) {
            this.goBack();
        }
    };

    onConfirmChange = (e) => {
        if (e.target.value.toLowerCase() === 'k') {
            this.submitAnswer();
        } else {
            this.setState({
                consoleState: 'confess'
            }, () => {
                this.consoleInput.textContent = this.state.inputValue;
                this.setFocus();
            });
        }
    }

    onSubmittedChange = (e) => {
        if (this.state.consoleState === 'submitted') {
            this.gotoEnd();
        }
    }

    goBack = () => {
        this.props.history.push('/console/');
    };

    gotoEnd() {
        this.props.history.push({
            pathname: '/end/',
            state: {
                confession: this.state.confession
            }
        });
    }

    // gotoSweepstakes() {
    //     console.log("confession", this.state.confession);
    //     this.props.history.push({
    //         pathname: '/console/win/',
    //         state: {
    //             confession: this.state.confession
    //         }
    //     });
    // }

    evalInput(str) {
        if (str.toLowerCase() === 'x') {
            this.goBack();
        } else if (str) {
            this.setState({
                confession: str,
                consoleState: 'confirm'
            }, () => {
                this.setFocus();
            });
        } else {
            this.consoleInput.textContent = '';
        }
    }

    submitAnswer = () => {
        this.setState({ consoleState: 'submitting' });
        let that = this;
        setTimeout(() => {
            this.answersRequest = axios
                .post(Settings.baseUrl + '/api/confession',
                    this.state.confession.replace(/\u00A0/g, ' '),
                    { headers: { "Content-Type": "text/plain" } })
                .then((result) => {
                    that.setState({ consoleState: 'submitted' }, () => {
                        this.setFocus();
                    });
                })
                .catch((error) => {
                    console.log(error);
                    alert('Pahoittelemme, tallennuksessa tapahtui virhe.');
                    this.goBack();
                });
        }, 500);
    }

    setFocus = () => {
        if (this.state.consoleState === 'confess') {
            this.consoleInput.focus({ preventScroll: true });
            this.setEndOfContenteditable(this.consoleInput);
        } else if (this.state.consoleState === 'confirm') {
            this.confirmInput.focus({ preventScroll: true });
        } else if (this.state.consoleState === 'submitted') {
            this.submittedInput.focus({ preventScroll: true });
        }
    };

    setEndOfContenteditable(contentEditableElement) {
        if (this.state.consoleState === 'confess') {
            var range, selection;
            if (document.createRange) {
                range = document.createRange();
                range.selectNodeContents(contentEditableElement);
                range.collapse(false);
                selection = window.getSelection();
                selection.removeAllRanges();
                selection.addRange(range);
            } else if(document.selection) {
                range = document.body.createTextRange();
                range.moveToElementText(contentEditableElement);
                range.collapse(false);
                range.select();
            }
        }
    }

    setListeners = () => {
        this.consoleInput.addEventListener('paste', e => {
            e.preventDefault();
        });
        this.consoleInput.addEventListener('keydown', e => {
            if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
                e.preventDefault();
            }
            this.setEndOfContenteditable(e.target);
        });
    }

    render() {

        return (<div onClick={this.setFocus}>
            {this.state.consoleState !== 'submitted' &&
                <div>
                    <p>**** TUNNUSTUS ****</p>
                    <p>&nbsp;</p>
                    <p>OLE ROHKEA JA KIRJOITA MITÄ OLET TEHNYT. Syntisimmät tunnustukset tallennamme konsolimme syntien
                        kansioon.</p>
                    <p>Lähetä tunnustus painamalla enter, tai peruuta syöttämällä esc tai x.</p>
                    <p>&nbsp;</p>
                    {this.state.consoleState === 'confess' &&
                        <div className="mb-4">
                            <div className={this.state.waiting ? 'd-none' : "console-input-display"} ref={(el) => {
                                this.inputDisplay = el
                            }}><span type="text" disabled={this.state.waiting}
                                onKeyDown={e => this.setEndOfContenteditable(e.target)}
                                onInput={e => this.onConsoleChange(e)}
                                onKeyUp={e => this.onConsoleKeyUp(e)}
                                className="console-hidden-input text-select text-break"
                                contentEditable="true"
                                ref={(input) => { this.consoleInput = input }}></span>
                                </div>
                        </div>
                    }

                </div>
            }

            {this.state.consoleState === 'confirm' &&
                <div>
                    <p>{this.state.confession}</p>
                    <p>&nbsp;</p>
                    <p>Vahvista tunnustuksesi painamalla (K) tai peruuta painamalla (E)</p>
                    <input type="text" className="opa-hidden"
                        onChange={e => this.onConfirmChange(e)}
                        ref={(input) => { this.confirmInput = input }}/>
                </div>
            }

            {this.state.consoleState === 'submitting' &&
                <div>
                    <p>Lähetetään…</p>
                </div>
            }

            {this.state.consoleState === 'submitted' &&
                <div>
                    <p>Tilanteesi ei ole niin paha kuin luulet. Ohjelmistokehittäminen on elämän pituinen oppitaival. Päästäksesi takaisin oikealle polulle, tulee sinun lukea ajatuksella seuraavat kirjat</p>
                    <p>&nbsp;</p>
                    {this.state.randomBooks.map((book, index) => {
                        return (
                            <p key={index}>--&gt; {book.title}</p>
                        )
                    })}
                    <p>&nbsp;</p>
                    <p>Tämän jälkeen syntisi on sovitettu ja voit taas koodata rauhassa.</p>
                    <p>&nbsp;</p>
                    <p>Poistu painamalla mitä tahansa näppäintä.</p>
                    <input type="text" className="opa-hidden"
                        onChange={e => this.onSubmittedChange(e)}
                        ref={(input) => { this.submittedInput = input }}/>
                </div>
            }

        </div>);
    }
}

Confess.getDerivedStateFromProps = (nextProps, prevState) => {
    return {
        randomBooks: _.shuffle(nextProps.books).slice(0, 2)
    };
};
