import TweenMax from 'gsap/umd/TweenMax';
import TimelineMax from 'gsap/umd/TimelineMax';
import React from 'react';
import _ from 'lodash';

export class WelcomeView extends React.Component {

    state = {
        currentQuoteIndex: 0,
        currentQuoteText: '',
        randomQuotes: [],
        welcomeShown: false
    };

    quoteSettings = {
        maxWidth: 400,
        maxHeight: 400,
        color: '#ffffff',
        margin: 50, // page margin
        delay: 5 // seconds
    };

    timeline = new TimelineMax();

    // get a random position inside the container, so that the quote fits in it
    getRandomPos() {
        const xPos = Math.ceil(this.quoteSettings.margin + Math.random() * (window.innerWidth - (this.quoteSettings.margin * 2) - this.quoteSettings.maxWidth));
        const yPos = Math.ceil(this.quoteSettings.margin + Math.random() * (window.innerHeight - (this.quoteSettings.margin * 2) - this.quoteSettings.maxHeight));
        return {
            x: xPos > 0 ? xPos : 10,
            y: yPos > 0 ? yPos : 10
        }
    }

    swapQuote = () => {
        this.setState({
            currentQuoteIndex: (this.state.currentQuoteIndex + 1) % this.state.randomQuotes.length,
            currentQuoteText: this.state.currentQuoteIndex === 0 ? this.props.soundsOnText : this.state.randomQuotes[this.state.currentQuoteIndex].text,
        });
    };

    showWelcomeMessage = () => {
        if (!this.state.welcomeShown) {
            this.setState({ welcomeShown: true });
            this.timeline.seek(this.timeline.duration() - 1)
        }
    };

    gotoScene = (scene) => {
        this.props.backgroundMusic.fade(this.props.musicVolume,0,0.9);
        TweenMax.to(this.viewContainer, 1, {opacity: 0, onComplete:() => {
            this.props.backgroundMusic.stop();
            this.props.history.push(scene);
        }});
    };

    componentDidMount() {

        // randomize quotes from props
        this.setState({
           randomQuotes: _.shuffle(this.props.quotes)
        });

        // music
        this.props.backgroundMusic.stop();
        this.props.backgroundMusic.volume(this.props.musicVolume);
        this.props.backgroundMusic.play();

        this.timeline
            // show music notification
            // .to(this.quoteContainer, 0, {delay: 0.5, opacity: 1, ...this.getRandomPos()})
            .call(this.swapQuote)
            // .to(this.quoteContainer, 0, {delay: 3, opacity: 0})

            // show eyes briefly
            // .to(this.eyes, 0, {delay: 0.5, opacity: 1, ...this.getRandomPos()})
            // .to(this.eyes, 0, {delay: 2, opacity: 0})

            // show first quote
            .to(this.quoteContainer, 0, {delay: 0.5, opacity: 1, ...this.getRandomPos()})
            .call(this.swapQuote)

            // show second quote
            .to(this.quoteContainer, 0, {delay: this.quoteSettings.delay, opacity: 1, ...this.getRandomPos()})
            .call(this.swapQuote)

            // show third quote
            .to(this.quoteContainer, 0, {delay: this.quoteSettings.delay, opacity: 1, ...this.getRandomPos()})
            .call(this.swapQuote)

            // hide quotes
            // .to(this.quoteContainer, 0, {delay: this.quoteSettings.delay, opacity: 0})

            // show eyes
            // .to(this.eyes, 0, {delay: 0.5, opacity: 1, ...this.getRandomPos()})
            // .to(this.eyes, 0, {delay: 2, opacity: 0})

            // show fourth quote
            .to(this.quoteContainer, 0, {delay: this.quoteSettings.delay, opacity: 1, ...this.getRandomPos()})
            .call(this.swapQuote)

            // show fifth quote
            .to(this.quoteContainer, 0, {delay: this.quoteSettings.delay, opacity: 1, ...this.getRandomPos()})
            .call(this.swapQuote)

            // hide quotes and show welcome
            .to(this.quoteContainer, 0, {delay: this.quoteSettings.delay, opacity: 0})
            .call(() => {
                this.setState({ welcomeShown: true });
            })
            .to(this.welcome, 1, {opacity: 1});
    }

    componentWillUnmount() {
        this.props.backgroundMusic.fade(this.props.musicVolume, 0, 1000);
    }

    render() {
        return (
            <div className="view-container" onClick={ this.showWelcomeMessage } ref={(el) => { this.viewContainer = el }}>
                <div className="container">
                    <div className="row justify-content-center text-center">
                        <div className="col-12 col-sm-8 col-md-7 col-lg-6 welcome-message" ref={(el) => { this.welcome = el }}>
                            <div className="welcome-message-inner">
                                <p>Kehittäjän on välillä uskallettava katsoa myös taaksepäin.</p>
                                <p>Olisiko bugi jos toinenkin jäänyt lähdekoodiin? Muistatko milloin viimeksi pidit code reviewin?</p>
                                <p>Omien virheiden tunnustaminen on kaiken viisauden alku, ja me olemme tehneet tunnustamisesta helppoa. Pian huomaat kuinka ryhtisi palautuu taakan karistessa harteiltasi.</p>
                                <button className="btn btn-primary mt-3" onClick={() => { this.gotoScene('/animation') }}>Tunnusta syntisi</button>
                                <button className={"skip-link"} onClick={() => { this.gotoScene('/console') }}>> &nbsp; Tuttu juttu, vie minut konsoliin.</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"quote-container" + (this.state.welcomeShown ? " d-none" : "")} style={this.state.quoteContainerStyle} ref={(el) => { this.quoteContainer = el }}>{ this.state.currentQuoteText.replace(/\u00A0/g, ' ') }</div>
                <img src="assets/images/eyes-move.gif" alt="Eyes" className="eyes" style={this.state.eyesStyle} ref={(el) => { this.eyes = el }} />
            </div>
        );
    }
}

