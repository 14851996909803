import React from 'react';
import TweenMax from "gsap/umd/TweenMax";

export class TermsView extends React.Component {

    componentDidMount() {
        TweenMax.to(this.viewContainer, 0.5, {opacity: 1});
    }

    fadeOut = () => {
        TweenMax.to(this.viewContainer, 0.5, {opacity: 0, onComplete:() => {
            this.props.onAgreeClick();
        }});
    };

    render() {
        return (
            <div className="terms-container justify-content-center align-middle" ref={(el) => { this.viewContainer = el }}>
                <p className="m-0 p-2 paper-color">Käyttämällä sivustoa hyväksyt Bitwise Oy:n tietosuojaehdot. Lue tietosuojaseloste <a href="https://bitwise.fi/tietosuojaseloste/" target="_blank" rel="noopener noreferrer">täältä.</a>&nbsp;&nbsp;</p>
                <button className="btn btn-primary mr-2" onClick={ this.fadeOut }>Hyväksyn ehdot</button>
            </div>
        )
    }

    // render() {
    //     return (
    //         <div className="view-container" style={{opacity: 0}} ref={(el) => { this.viewContainer = el }}>
    //             <div className="container py-5">
    //                 <div className="row justify-content-center">
    //                     <div className="col-12 col-md-8 px-4">
    //                         <h1 className="h5 text-center mb-3">Tärkeää</h1>
    //                         <p>Bitwise Oy:n markkinointi&shy;rekisteriä käytetään Bitwise Oy:n tuotteiden, ratkaisujen, asiantuntijapalveluiden, tapahtumien, kampanjoiden ja työpaikkojen  suora&shy;markkinointiin sekä näihin rinnastettaviin toimiin.</p><p>Bitwise Oy:n henkilötietolain 10 §:n mukainen rekisteriseloste on luettavissa kokonaisuudessaan <a href="https://bitwise.fi/asiakas/markkinointirekisteri/" target="_blank" rel="noopener noreferrer">bitwise.fi/<wbr/>asiakas/<wbr/>markkinointirekisteri/</a></p>
    //                         <div className="text-center mt-4">
    //                             <button className="btn btn-primary" onClick={ this.fadeOut }>Ok, hyväksyn</button>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     );
    // }
}
